import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useKeycloak } from 'react-keycloak';
import { AlertUI } from '../../componentsUI/Alert';
import Loading from '../../components/Common/Loading';
import { main } from '../../themes/main';
import { GET_HOSPITALS } from '../../graphql/queries';
import { CREATE_HOSPITAL_WITHOUT_LOGO } from '../../graphql/mutations';
import { AppContainer } from '../../components/Common/styled/AppContainer';
import { EmptyPage } from '../../components/Common/styled/EmptyPage';
import { networkErrorParse, WelcomeErrorParse } from '../../utils/ErrorGraphQLUtils';


const defaultHospital = {
  name: 'Your first hospital',
  streetAddress: 'Passeig de Gràcia, 123',
  postalCode: '08007',
  city: 'Barcelona',
  country: 'ES',
  mainColor: main.colors.primary, // '#3b75ce',
};

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [createDefaultHospital, { error }] = useMutation(CREATE_HOSPITAL_WITHOUT_LOGO, {
    onCompleted: ({ createHospital }) => {
      dispatch({ type: 'CHANGE_HOSPITAL', current: createHospital });
      window.history.back();
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
    onError: () => {},
  });

  useEffect(() => {
    createDefaultHospital({ variables: { hospitalInput: defaultHospital } });
  }, []);

  const createHospitalError = networkErrorParse(error);

  const errorType = createHospitalError === 'max.hospitals.reached' ? 'Info' : 'Error';
  const errorMessage = createHospitalError === 'max.hospitals.reached' ? 'request.team.member.warning' : createHospitalError;

  const ErrorMessage = () => (
    errorMessage && errorMessage.includes('it.already.exists.hospital.with.institution.name')
      ? <WelcomeErrorParse error={errorMessage} />
      : (
        <AlertUI severity={errorType.toLowerCase()} title={errorType}>
          {t(errorMessage)}
        </AlertUI>
      ));

  return (
    <ThemeProvider theme={main}>
      {error ? (
        <AppContainer>
          <EmptyPage>
            <ErrorMessage />
          </EmptyPage>
        </AppContainer>
      ) : (
        <Loading />
      )}
    </ThemeProvider>
  );
};
